import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllCountryApi = () => api.get(url.GET_ALL_COUNTRY_LIST);

export const getAllActiveCountriesApi = () =>
  api.get(url.GET_ALL_ACTIVE_COUNTRIES);

export const findByIdCountryApi = (params) =>
  api.get(url.FIND_BY_ID_COUNTRY_URL, params);

export const insertCountryApi = (data) =>
  api.create(url.INSERT_COUNTRY_URL, data);

export const updateCountryApi = (data) =>
  api.create(url.UPDATE_COUNTRY_URL, data);

export const deleteCountryApi = (data) =>
  api.create(url.DELETE_COUNTRY_URL, data);

export const ActiveInActiveCountryApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_COUNTRY_URL, data);
