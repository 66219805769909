import { ErrorMessage, Field } from "formik";
import React from "react";
import { Col, Label, Row } from "reactstrap";
import { WeekDays } from "../../helpers/date_helper";
import PropTypes from "prop-types";

const Weeks = (props) => {
  const { title, name, disabledDays = [] } = props;
  const isDisabled = (dayIndex) => {
    return disabledDays.includes(dayIndex.toString());
  };

  return (
    <React.Fragment>
      <Col lg={12} className="my-2">
        <h5>{title}</h5>
      </Col>
      <Col lg={12}>
        <Row>
          {WeekDays.map((day, index) => (
            <Col lg={1} key={index}>
              <div className="form-check mb-4 mt-4">
                <Field
                  type="checkbox"
                  name={name}
                  disabled={isDisabled(index + 1)}
                  className="form-check-input"
                  id={`${title}-${name}-${index}`}
                  value={(index + 1).toString()}
                />
                <Label
                  className="form-check-label"
                  htmlFor={`${title}-${name}-${index}`}
                >
                  {day.slice(0, 3)}
                </Label>
              </div>
            </Col>
          ))}

          <ErrorMessage name={name} component="div" className="text-danger" />
        </Row>
      </Col>
    </React.Fragment>
  );
};

Weeks.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};

export default Weeks;
