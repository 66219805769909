import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import {
  activeInactiveMealTypeApi,
  deleteMealTypeApi,
  findByIDMealTypeApi,
  getAllActiveMealTypesApi,
  getAllMealTypesApi,
  insertMealTypeApi,
  updateMealTypeApi,
} from "./meal.services";

export const getAllActiveMealTypes = createAsyncThunk(
  "MealTypes/FindAllActive",
  async () => {
    try {
      const response = getAllActiveMealTypesApi();
      return response;
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllMealTypes = createAsyncThunk(
  "MealTypes/FindAll",
  async () => {
    try {
      const response = await getAllMealTypesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdMealType = createAsyncThunk(
  "MealTypes/FindByID",
  async (params) => {
    try {
      const response = await findByIDMealTypeApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertMealType = createAsyncThunk(
  "MealTypes/Insert",
  async (data) => {
    try {
      const response = await insertMealTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateMealType = createAsyncThunk(
  "MealTypes/Update",
  async (data) => {
    try {
      const response = await updateMealTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveMealType = createAsyncThunk(
  "MealTypes/ActiveInActive",
  async (data) => {
    try {
      const response = await activeInactiveMealTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteMealType = createAsyncThunk(
  "MealTypes/Delete",
  async (data) => {
    try {
      const response = await deleteMealTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
