import { createSlice } from "@reduxjs/toolkit";
import {
  deleteMealType,
  findByIdMealType,
  getAllActiveMealTypes,
  getAllMealTypes,
  insertMealType,
  updateMealType,
} from "./thunk";
import _ from "lodash";

export const mealTypeForm = {
  ID: null,
  MealType: "",
  IsActive: true,
};

export const initialState = {
  mealTypeDetail: {
    form: mealTypeForm,
  },
  mealTypes: [],
  error: {},
  loading: false,
};

const MealType = createSlice({
  name: "MealType",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.mealTypeDetail.form = mealTypeForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllActiveMealTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllActiveMealTypes.fulfilled, (state, action) => {
      state.mealTypes = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllActiveMealTypes.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });
    builder.addCase(getAllMealTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllMealTypes.fulfilled, (state, action) => {
      state.mealTypes = action.payload.Data || [];
      state.loading = false;
    });
    builder.addCase(getAllMealTypes.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.mealTypes = initialState.mealTypes;
      state.loading = false;
    });
    builder.addCase(insertMealType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertMealType.fulfilled, (state) => {
      state.mealTypeDetail.form = mealTypeForm;
      state.loading = false;
    });
    builder.addCase(insertMealType.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(updateMealType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMealType.fulfilled, (state) => {
      state.mealTypeDetail.form = mealTypeForm;
      state.loading = false;
    });
    builder.addCase(updateMealType.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(deleteMealType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMealType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMealType.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(findByIdMealType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findByIdMealType.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data;

      let formKeys = Object.keys(mealTypeForm);
      formObj = _.pick(formObj, formKeys);
      state.mealTypeDetail.form = formObj;
      state.loading = false;
    });
    builder.addCase(findByIdMealType.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
  },
});

export const { resetForm } = MealType.actions;

export default MealType.reducer;
