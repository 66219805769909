import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveMealTypesApi = () =>
  api.get(url.GET_ALL_ACTIVE_MEAL_TYPES);

export const getAllMealTypesApi = () => api.get(url.GET_ALL_MEAL_TYPES);

export const insertMealTypeApi = (data) =>
  api.create(url.INSERT_MEAL_TYPE, data);

export const updateMealTypeApi = (data) =>
  api.create(url.UPDATE_MEAL_TYPE, data);

export const findByIDMealTypeApi = (params) =>
  api.get(url.FIND_BY_ID_MEAL_TYPE, params);

export const activeInactiveMealTypeApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_MEAL_TYPE, data);

export const deleteMealTypeApi = (data) =>
  api.create(url.DELETE_MEAL_TYPE, data);
