import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

//AuthenticationInner pages
import BasicSignIn from "../pages/AuthenticationInner/Login/BasicSignIn";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import BasicSignUp from "../pages/AuthenticationInner/Register/BasicSignUp";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import BasicPasswReset from "../pages/AuthenticationInner/PasswordReset/BasicPasswReset";
//pages
import Starter from "../pages/Pages/Starter/Starter";
import Maintenance from "../pages/Pages/Maintenance/Maintenance";
import ComingSoon from "../pages/Pages/ComingSoon/ComingSoon";

import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BasicLockScreen from "../pages/AuthenticationInner/LockScreen/BasicLockScr";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import BasicLogout from "../pages/AuthenticationInner/Logout/BasicLogout";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import BasicSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import BasicTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import Alt404 from "../pages/AuthenticationInner/Errors/Alt404";
import Error500 from "../pages/AuthenticationInner/Errors/Error500";

import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Quotations
import QuotationMaster from "../pages/Quotation/QuotationMaster";
import QuotationTab from "../pages/Quotation/QuotationTab";
import CreateAutoQuotation from "../pages/Quotation/AutoQuotation/CreateAutoQuotation";
import GenerateQuotation from "../pages/Quotation/AutoQuotation/GenerateQuotation";
import AutoQuotationMaster from "../pages/AutoQuotation/AutoQuotationMaster";

// Hotel
import HotelMaster from "../pages/Hotel/HotelMaster";
import CreateHotel from "../pages/Hotel/CreateHotel";
import CreateTour from "../pages/Tour/CreateTour";

// Tour
import TourMaster from "../pages/Tour/TourMaster";
// import AutoQuotationCreate from "../pages/Quotation/AutoQuotationCreate"

//Sector
import MasterSector from "../pages/Sector/MasterSector";
import CreateSector from "../pages/Sector/CreateSector";
import MasterVehicle from "../pages/Vehicle/MasterVehicle";
import CreateVehicle from "../pages/Vehicle/CreateVehicle";
import MasterCity from "../pages/City/MasterCity";
import CreateCity from "../pages/City/CreateCity";
import MasterUser from "../pages/User/MasterUser";
import CreateUser from "../pages/User/CreateUser";
import MasterCountry from "../pages/Country/MasterCountry";
import CreateCountry from "../pages/Country/CreateCountry";
import MasterState from "../pages/State/MasterState";
import CreateState from "../pages/State/CreateState";
import MasterSpeciality from "../pages/TourspecialityType/MasterSpeciality";
import CreateSpeciality from "../pages/TourspecialityType/CreateSpeciality";
import MasterTermAndCondition from "../pages/TermsAndCondition/MasterTermAndCondition";
import CreateTermAndCondition from "../pages/TermsAndCondition/CreateTermAndCondition";
import MasterVehicleType from "../pages/VehicleType/MasterVehicleType";
import CreateVehicleType from "../pages/VehicleType/CreateVehicleType";
//subsector
import CreateSubSector from "../pages/SubSector/CreateSubSector";
import MasterSubSector from "../pages/SubSector/MasterSubSector";
//lead source
import MasterLeadSource from "../pages/LeadSource/MasterLeadSource";
import CreateLeadSource from "../pages/LeadSource/CreateLeadSource";
//festivals
import MasterFestival from "../pages/Festivals/MasterFestival";
import CreateFestival from "../pages/Festivals/CreateFestival";
//gst
import MasterGST from "../pages/GST/MasterGST";
import CreateGST from "../pages/GST/CreateGST";

import MasterAgent from "../pages/Agent/MasterAgent";
import CreateAgent from "../pages/Agent/CreateAgent";
import MasterItinerary from "../pages/Itinerary/MasterItinerary";
import CreateItinerary from "../pages/Itinerary/CreateItinerary";
import InquiryMaster from "../pages/Inquiry/InquiryMaster";
import InquiryCreate from "../pages/Inquiry/InquiryCreate";
import PandingInquiryList from "../pages/Inquiry/PandingInquiryList";
//cancellation policy
import MasterCancelationPolicy from "../pages/CancelationPolicy/MasterCancelationPolicy";
import CreateCancelationPolicy from "../pages/CancelationPolicy/CreateCancelationPolicy";
//tour facility
import MasterTourFacility from "../pages/TourFacility/MasterTourFacility";
import CreateTourFacility from "../pages/TourFacility/CreateTourFacility";
import MasterPayment from "../pages/Payment/MasterPayment";
import CreatePayment from "../pages/Payment/CreatePayment";
import MasterMealType from "../pages/MealType/MasterMealType";
import CreateMealType from "../pages/MealType/CreateMealType";
const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },

  //Quotations
  { path: "/quotations", component: <QuotationMaster /> },
  {
    path: "/auto-quotations/:TOURID/create",
    component: <CreateAutoQuotation />,
  },
  {
    path: "/auto-quotations",
    component: <AutoQuotationMaster />,
  },
  {
    path: "/auto-quotations/:TOURID/generate-quotation/:ID",
    component: <GenerateQuotation />,
  },
  { path: "/quotations/create", component: <QuotationTab /> },
  { path: "/quotations/update/:ID", component: <QuotationTab /> },
  { path: "/pages-starter", component: <Starter /> },

  // Hotels
  { path: "/hotels", component: <HotelMaster /> },
  { path: "/hotels/create", component: <CreateHotel /> },
  { path: "/hotels/:ID", component: <CreateHotel /> },

  // Tours
  { path: "/tours", component: <TourMaster /> },
  // { path: "/tours/:ID", component: <CreateTour /> },
  { path: "/tours/create", component: <CreateTour /> },
  { path: "/tours/update/:ID", component: <CreateTour /> },

  // Sector
  { path: "/sector", component: <MasterSector /> },
  { path: "/sector/create", component: <CreateSector /> },
  { path: "/sector/update/:ID", component: <CreateSector /> },

  //subsector
  { path: "/subsector", component: <MasterSubSector /> },
  { path: "/subsector/create", component: <CreateSubSector /> },
  { path: "/subsector/update/:ID", component: <CreateSubSector /> },

  //lead source
  { path: "/leadsource", component: <MasterLeadSource /> },
  { path: "/leadsource/create", component: <CreateLeadSource /> },
  { path: "/leadsource/update/:ID", component: <CreateLeadSource /> },

  //festivals
  //lead source
  { path: "/festivals", component: <MasterFestival /> },
  { path: "/festivals/create", component: <CreateFestival /> },
  { path: "/festivals/update/:ID", component: <CreateFestival /> },

  // Vehicle type
  { path: "/vehicletype", component: <MasterVehicleType /> },
  { path: "/vehicletype/create", component: <CreateVehicleType /> },
  { path: "/vehicletype/update/:ID", component: <CreateVehicleType /> },

  // Vehicle
  { path: "/vehicle", component: <MasterVehicle /> },
  { path: "/vehicle/create", component: <CreateVehicle /> },
  { path: "/vehicle/update/:ID", component: <CreateVehicle /> },

  //country
  { path: "/country", component: <MasterCountry /> },
  { path: "/country/create", component: <CreateCountry /> },
  { path: "/country/update/:ID", component: <CreateCountry /> },

  //mealType
  { path: "/mealtype", component: <MasterMealType /> },
  { path: "/mealtype/create", component: <CreateMealType /> },
  { path: "/mealtype/update/:ID", component: <CreateMealType /> },

  //cancellation policy
  { path: "/cancellationpolicy", component: <MasterCancelationPolicy /> },
  {
    path: "/cancellationpolicy/create",
    component: <CreateCancelationPolicy />,
  },
  {
    path: "/cancellationpolicy/update/:ID",
    component: <CreateCancelationPolicy />,
  },

  //state
  { path: "/state", component: <MasterState /> },
  { path: "/state/create", component: <CreateState /> },
  { path: "/state/update/:ID", component: <CreateState /> },

  // City
  { path: "/city", component: <MasterCity /> },
  { path: "/city/create", component: <CreateCity /> },
  { path: "/city/update/:ID", component: <CreateCity /> },

  //state
  { path: "/tourfacility", component: <MasterTourFacility /> },
  { path: "/tourfacility/create", component: <CreateTourFacility /> },
  { path: "/tourfacility/update/:ID", component: <CreateTourFacility /> },

  // GST
  { path: "/gst", component: <MasterGST /> },
  { path: "/gst/create", component: <CreateGST /> },
  { path: "/gst/update/:ID", component: <CreateGST /> },

  // speciality type
  { path: "/specialitytype", component: <MasterSpeciality /> },
  { path: "/specialitytype/create", component: <CreateSpeciality /> },
  { path: "/specialitytype/update/:ID", component: <CreateSpeciality /> },

  // Terms and conditions
  { path: "/termandcondition", component: <MasterTermAndCondition /> },
  { path: "/termandcondition/create", component: <CreateTermAndCondition /> },
  {
    path: "/termandcondition/update/:ID",
    component: <CreateTermAndCondition />,
  },

  // speciality type
  { path: "/itinerary", component: <MasterItinerary /> },
  { path: "/itinerary/create", component: <CreateItinerary /> },
  { path: "/itinerary/update/:ID", component: <CreateItinerary /> },

  // Inquiry
  { path: "/inquiry", component: <InquiryMaster /> },
  { path: "/pendinginquiry", component: <PandingInquiryList /> },
  { path: "/inquiry/create", component: <InquiryCreate /> },
  { path: "/inquiry/update/:ID", component: <InquiryCreate /> },

  // User
  { path: "/users", component: <MasterUser /> },
  { path: "/users/create", component: <CreateUser /> },
  { path: "/users/update/:ID", component: <CreateUser /> },

  // Agent
  { path: "/agents", component: <MasterAgent /> },
  { path: "/agents/create", component: <CreateAgent /> },
  { path: "/agents/update/:ID", component: <CreateAgent /> },

  // Payment
  { path: "/payments", component: <MasterPayment /> },
  { path: "/payments/create", component: <CreatePayment /> },
  { path: "/payments/update/:ID", component: <CreatePayment /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/quotations" />,
  },
  { path: "*", component: <Navigate to="/quotations" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  { path: "/auth-signup-basic", component: <BasicSignUp /> },
  { path: "/auth-signup-cover", component: <CoverSignUp /> },
  { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  { path: "/auth-logout-basic", component: <BasicLogout /> },
  { path: "/auth-logout-cover", component: <CoverLogout /> },
  { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-404-cover", component: <Cover404 /> },
  { path: "/auth-404-alt", component: <Alt404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/pages-coming-soon", component: <ComingSoon /> },

  { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  { path: "/auth-offline", component: <Offlinepage /> },
];

export { authProtectedRoutes, publicRoutes };
