import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

//Calendar
import CalendarReducer from "./calendar/reducer";
//Chat
import chatReducer from "./chat/reducer";
//Ecommerce
import EcommerceReducer from "./ecommerce/reducer";

//Project
import ProjectsReducer from "./projects/reducer";

// Tasks
import TasksReducer from "./tasks/reducer";

//Crypto
import CryptoReducer from "./crypto/reducer";

//TicketsList
import TicketsReducer from "./tickets/reducer";
//Crm
import CrmReducer from "./crm/reducer";

//Invoice
import InvoiceReducer from "./invoice/reducer";

//Mailbox
import MailboxReducer from "./mailbox/reducer";

// Dashboard Analytics
import DashboardAnalyticsReducer from "./dashboardAnalytics/reducer";

// Dashboard CRM
import DashboardCRMReducer from "./dashboardCRM/reducer";

// Dashboard Ecommerce
import DashboardEcommerceReducer from "./dashboardEcommerce/reducer";

// Dashboard Cryto
import DashboardCryptoReducer from "./dashboardCrypto/reducer";

// Dashboard Cryto
import DashboardProjectReducer from "./dashboardProject/reducer";

// Dashboard NFT
import DashboardNFTReducer from "./dashboardNFT/reducer";

// Pages > Team
import TeamDataReducer from "./team/reducer";

// File Manager
import FileManagerReducer from "./fileManager/reducer";

// Job
import JobReducer from "./jobs/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

// Quotation
import QuotationReducer from "./quotation/reducer";

// Sector
import SectorReducer from "./sector/reducer";

// Sub Sector
import SubSectorReducer from "./subsector/reducer";

// Tour
import TourReducer from "./tour/reducer";

// Agent
import AgentReducer from "./agent/reducer";

// PaymentReceipt
import PaymentReceiptReducer from "./payment/reducer";

// Cities
import CityReducer from "./city/reducer";

// Branches
import BranchReducer from "./branch/reducer";

// Hotel
import HotelReducer from "./hotel/reducer";

// Rooms
import RoomCategoriesReducer from "./room/reducer";

// Meal
import MealTypeReducer from "./mealtype/reducer";

// Vehicle
import VehicleReducer from "./vehicle/reducer";

// Inquiry
import InquiryReducer from "./inquiry/reducer";

//leadsource
import LeadSourceReducer from "./leadsource/reducer";

//VehicleType
import VehicleTypeReducer from "./vehicletype/reducer";
// User
import userReducer from "./user/reducer";

// Country
import CountryReducer from "./country/reducer";

// State
import StateReducer from "./state/reducer";

// Modal Common
import ModalCommonReducer from "./modalcommon/reducer";

// Festival
import FestivalReducer from "./festival/reducer";

// Speciality Types
import SpecialityTypeReducer from "./specialitytype/reducer";

// Terms and Conditions
import TermsAndConditionsReducer from "./termsandconditions/reducer";

// Cancelation Policy
import CancellationPolicyReducer from "./canelationpolicy/reducer";

// Itinerary
import ItineraryReducer from "./itinerary/reducer";
import GstReducer from "./gst/reducer";
// Pagination

import PaginationReducer from "./pagination/reducer";

// Regions
import RegionReducer from "./region/reducer";

//tour facility
import TourFacilitieReducer from "./tourfacilities/reducer";

//
import AutoQuotationReducer from "./autoquotation/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Calendar: CalendarReducer,
  Chat: chatReducer,
  Projects: ProjectsReducer,
  Ecommerce: EcommerceReducer,
  Tasks: TasksReducer,
  Crypto: CryptoReducer,
  Tickets: TicketsReducer,
  Crm: CrmReducer,
  Invoice: InvoiceReducer,
  Mailbox: MailboxReducer,
  DashboardAnalytics: DashboardAnalyticsReducer,
  DashboardCRM: DashboardCRMReducer,
  DashboardEcommerce: DashboardEcommerceReducer,
  DashboardCrypto: DashboardCryptoReducer,
  DashboardProject: DashboardProjectReducer,
  DashboardNFT: DashboardNFTReducer,
  Team: TeamDataReducer,
  FileManager: FileManagerReducer,
  Jobs: JobReducer,
  APIKey: APIKeyReducer,
  Quotation: QuotationReducer,
  Sector: SectorReducer,
  SubSector: SubSectorReducer,
  Tour: TourReducer,
  Agent: AgentReducer,
  City: CityReducer,
  Hotel: HotelReducer,
  RoomCategories: RoomCategoriesReducer,
  MealType: MealTypeReducer,
  Vehicle: VehicleReducer,
  VehicleType: VehicleTypeReducer,
  Country: CountryReducer,
  State: StateReducer,
  ModalCommon: ModalCommonReducer,
  Festival: FestivalReducer,
  SpecialityType: SpecialityTypeReducer,
  TermsAndCondition: TermsAndConditionsReducer,
  CancellationPolicy: CancellationPolicyReducer,
  Itinerary: ItineraryReducer,
  Gst: GstReducer,
  Pagination: PaginationReducer,
  Region: RegionReducer,
  AutoQuotation: AutoQuotationReducer,
  User: userReducer,
  Branch: BranchReducer,
  LeadSource: LeadSourceReducer,
  Inquiry: InquiryReducer,
  TourFacility:TourFacilitieReducer,
  PaymentReceipt:PaymentReceiptReducer,
});

export default rootReducer;
